import { Fragment } from "react";

import HotelSliderLeft from "./HotelSliderLeft/HotelSliderLeft";
import HotelSliderRight from "./HotelSliderRight/HotelSliderRight";

import ChomuPalaceImages from "./ChomuPalaceImages/ChomuPalaceImages";
import RamadaImages from "./RamadaImages/RamadaImages";
import MarriottImages from "./MarriottJaipurImages/MarriottImages";
import LeMeridianImages from "./LeMeridianHydImgaes/LeMeridianImages";
import RadissionImages from "./RadissonAgraImages/RadissionAgraImages";
import NovotelJaipurImages from "./NovotelJaipurImages/NovotelJaipurImages";
import SheratonEdissionImages from "./SheratonEdisonImages/SheratonEdisonImages";

const ChomuPalaceAbout = `The Dangayach Group embarked on a remarkable mission to elevate the 350+ Year Old Chomu Palace, near Jaipur, into an opulent heritage hotel. With a rich history spanning centuries, the palace's conversion posed a daunting challenge. Through sheer dedication, the group succeeded in creating a lavish property with 100+ majestic rooms & suites. Chomu Palace is a beloved Bollywood shoot location, gracing many films & TVCs, and it stands as Rajasthan's premier wedding venue, adding to its storied legacy.

Beyond its age-old allure, the palace now boasts an array of captivating attractions for its visitors. The renowned "Sheesh Mahal" enchants guests with its mesmerizing glasswork, while the regal "Darbar Hall" stands as a testament to the palace's grandeur. The "Central Courtyard" offers a tranquil oasis, inviting visitors to bask in its serene ambiance. Additionally, the resplendent "Moti Mahal" beckons guests with its timeless beauty and charm.

Through meticulous work and unwavering commitment, the Dangayach Group has succeeded in preserving the 350+ Year Old historical significance of Chomu Palace while creating a haven of luxury and splendor. This magnificent transformation ensures that visitors can now experience the allure of the past while indulging in modern comforts and hospitality.`;

const RamadaAbout = `In 2008, the Dangayach Group developed a partnership with "Wyndham Hotels" USA, leading to the operation of this hotel under the renowned "Ramada" brand. This 4-star establishment, owned by the Dangayach Group, boasts 160 well-furnished rooms and a range of amenities to cater to its guests' needs. With three fine dining restaurants, multiple banquet spaces, and a rooftop swimming pool, the hotel offers a luxurious experience for its customers.

Since its inception, this hotel has made a significant impact in its category, revolutionizing the market and emerging as a frontrunner. Its commitment to quality, exceptional services, and top-notch facilities has established it as a leader in the industry. The Dangayach Group's unwavering dedication to providing an exceptional hospitality experience has ensured the continued success of this hotel under the Ramada brand.
`;

const MarriotAbout = `The Dangayach Group's long-cherished vision of bringing the largest 5-star hotel with the highest room count to Jaipur, Rajasthan, materialized with Marriott Jaipur. After years of construction, this remarkable hotel now stands tall, offering unparalleled hospitality to its customers.

With over 370 rooms, the hotel boasts elegant and well-appointed guest rooms, ensuring a comfortable and luxurious stay for every guest. The large banquet space caters to various events and gatherings, accommodating a wide range of needs. Additionally, the hotel offers ultramodern dining options, providing an exceptional culinary experience for its patrons.

The realization of this grand project showcases the Dangayach Group's commitment to excellence and its dedication to fulfilling the needs of discerning travelers. As the largest 5-star hotel in Rajasthan, it sets a new standard for hospitality in Jaipur, offering a harmonious blend of opulence, comfort, and cutting-edge amenities.
Marriott Jaipur serves as a testament to the Group's relentless pursuit of creating extraordinary spaces that redefine the art of hospitality.
`;

const LeMeridianAbout = `The Dangayach Group proudly presents Le Meridien Hyderabad, a 5-star hotel situated in the vibrant Gachibowli area. This exquisite establishment features 241 rooms and suites designed for optimal comfort, complemented by round-the-clock front desk services. Guests can indulge in a delightful culinary experience with a range of fine dining options available.

The Turquoise, an all-day dining restaurant, offers a menu showcasing Modern European and Asian cuisine, prepared in an interactive kitchen. The Jade bar is the perfect spot to unwind and savor crafted cocktails and Asian-inspired Tapas. Additionally, guests can enjoy access to the co-located Le Meridien Club Lounge, providing an exclusive and refined setting.

For business and event needs, the hotel offers three versatile meeting spaces spanning a total of 12,300 square feet. These spaces are equipped with state-of-the-art amenities and facilities, catering to the diverse requirements of today's travelers, whether for leisure, corporate, or social purposes.

With its exceptional accommodations, culinary offerings, and comprehensive meeting facilities, Le Meridien Hyderabad by the Dangayach Group promises a memorable and fulfilling experience for guests.
`;

const RadissonAgraAbout = `As part of its ongoing expansion in the hotel industry, the Dangayach Group introduced a new addition to its portfolio: a 4-star hotel in Agra operated under the prestigious Radisson brand. This hotel boasts 165 exquisitely designed and well-equipped guest rooms and suites, offering a luxurious and comfortable stay for its guests.

In addition to the beautifully appointed rooms, the hotel will feature a fine restaurant, a bar, a fitness center, and a swimming pool, ensuring a comprehensive range of amenities for guests to enjoy. What sets this hotel apart is its strategic location close to the iconic Taj Mahal. Many of the guest rooms and the restaurant offer breathtaking views of this world-famous monument, providing guests with an unforgettable experience.

With its prime location, elegant accommodations, and top-notch facilities, the Radisson Agra, operated by the Dangayach Group, promises to be a remarkable destination for travelers seeking a memorable stay in the city known for its architectural marvels.
`;

const NovotelJaipurAbout = `Novotel Hotel JECC Jaipur stands as a testament to the Dangayach Group's commitment to hospitality excellence. Part of this esteemed group, the hotel embodies its expertise in crafting remarkable experiences. Situated nearby Jaipur Exhibition and Convention Centre (JECC), the city's largest, it offers modernity and convenience.

With well-appointed rooms, Novotel Hotel ensures comfort for business and leisure travelers. Exceptional dining reflects the group's gastronomic dedication. The hotel's extensive meeting spaces cater to conferences, exhibitions, and weddings.

JECC itself shines as Jaipur's premier convention center, hosting weddings and events. Adjoining Novotel, it seamlessly complements the hotel's offerings. Novotel Hotel JECC Jaipur, under the Dangayach Group umbrella, promises unparalleled hospitality, combining comfort, convenience, and exceptional service. Amidst JECC and Novotel, guests experience an enriched stay that embodies the group's legacy of excellence.
`;

const SheratonAbout = `The Sheraton Edison Hotel Raritan Center, owned by the Dangayach Group, is a newly renovated full-service hotel located in the vibrant Raritan Center. With 276 guestrooms and a restaurant/bar that has undergone recent renovations, it offers modern comforts and delightful dining experiences. Its prime location steps away from the New Jersey Convention Center makes it an excellent choice for business travelers attending events. Additionally, guests can explore the attractions of New Jersey with ease. The hotel boasts up to 13,000 sq ft of flexible meeting space, catering to a range of corporate gatherings and events. Whether it's a small business meeting or a large conference, the Sheraton Edison Hotel Raritan Center provides comfort, convenience, and impressive facilities for a memorable stay.

Sheraton Edison Hotel Raritan Center is a top choice for business travelers & leisure seekers, looking for comfort, convenience, and excellent event facilities in the heart of Raritan Center.
`;

const Hotel = () => {
  return (
    <Fragment>
      <HotelSliderRight
        RoomImages={MarriottImages}
        category="Jaipur Marriott"
        about={MarriotAbout}
        anch="https://www.marriott.com/en-us/hotels/jaimc-jaipur-marriott-hotel/overview/"
      />
      <HotelSliderLeft
        RoomImages={LeMeridianImages}
        category="Le Méridien Hyderabad"
        about={LeMeridianAbout}
        anch="https://www.marriott.com/en-us/hotels/hydmd-le-meridien-hyderabad/overview/"
      />
      <HotelSliderRight
        RoomImages={RadissionImages}
        category="Radisson Agra"
        about={RadissonAgraAbout}
        anch="https://www.radissonhotels.com/en-us/hotels/radisson-agra"
      />
      <HotelSliderLeft
        RoomImages={RamadaImages}
        category="Ramada Jaipur"
        about={RamadaAbout}
        anch="http://www.ramadajaipur.com"
      />
      <HotelSliderRight
        RoomImages={ChomuPalaceImages}
        category="Chomu Palace"
        about={ChomuPalaceAbout}
        anch="http://www.chomupalacehotel.com"
      />
      <HotelSliderLeft
        RoomImages={SheratonEdissionImages}
        category="Sheraton Edison – NJ, USA"
        about={SheratonAbout}
        anch="https://www.marriott.com/en-us/hotels/ewrrs-sheraton-edison/overview/"
      />
      <HotelSliderRight
        RoomImages={NovotelJaipurImages}
        category="Novotel at JECC Jaipur"
        about={NovotelJaipurAbout}
        anch="#"
      />
    </Fragment>
  );
};

export default Hotel;
