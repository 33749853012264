import classes from "./NewsBox.module.css";

const NewsBox = (props) => {
  return (
    <div className={classes.container}>
      <picture>
        <img src={props.pic} alt="News" />
        <h1>{props.title}</h1>
        <h3>{props.date}</h3>
        <p>{props.description}</p>
        <a href={props.anch}>
          <button>Know More</button>
        </a>
      </picture>
    </div>
  );
};

export default NewsBox;
