import Values from "./Values";

import classes from "./CoreValues.module.css";

const CoreValues = () => {
  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <h1>CORE VALUES</h1>
        <h5>
          At the heart of the Dangayach Group lie core values of excellence,
          integrity, innovation, and customer-centricity. These guiding
          principles drive their pursuit of unparalleled quality, continuous
          improvement, and phenomenal customer experiences.
        </h5>
        <div className={classes.boxContainer}>
          <Values
            image={require("../../../Images/logo1.png")}
            title="Excellence"
            description="The uncompromising pursuit of superior quality and exceptional standards."
          />
          <Values
            image={require("../../../Images/logo2.png")}
            title="Innovation"
            description="Unleashing visionary ideas and embracing transformative advancements."
          />
          <Values
            image={require("../../../Images/logo3.png")}
            title="Customer-Centricity"
            description="Putting customers at the heart of everything we do."
          />
          <Values
            image={require("../../../Images/logo4.png")}
            title="Collaboration "
            description="Harnessing the power of collective expertise and synergy."
          />
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
