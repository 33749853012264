import React from "react";

import classes from "./roomLeft.module.css";

function Arrows({ prevSlide, nextSlide }) {
  return (
    <div className="arrow">
      <span className= {classes.prevImage} onClick={prevSlide}>
        &#10094;
      </span>
      <span className={classes.nextImage} onClick={nextSlide}>
        &#10095;
      </span>
    </div>
  );
}

export default Arrows;
