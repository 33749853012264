import First from "../../../../Images/ramadaJaipur1.jpeg";
import Second from "../../../../Images/ramadaJaipur2.jpeg";
import Third from "../../../../Images/ramadaJaipur3.jpeg";

const RamadaImages = [
  {
    title: `Ramada`,
    urls: First,
  },
  {
    title: `Ramada`,
    urls: Second,
  },
  {
    title: `Ramada`,
    urls: Third,
  },
];

export default RamadaImages;
