import First from "../../../../Images/sheratonImage1.jpeg";
import Second from "../../../../Images/sheratonImage2.jpeg";
import Third from "../../../../Images/sheratonImage3.jpeg";

const SheratonEdissionImages = [
  {
    title: `Sheraton Edission`,
    urls: First,
  },
  {
    title: `Sheraton Edission`,
    urls: Second,
  },
  {
    title: `Sheraton Edission`,
    urls: Third,
  },
];

export default SheratonEdissionImages;
