import classes from "./Team.module.css";

const Team = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
      </div>
      <div className={classes.infoContainer}>
        <h1>Our Team</h1>
        <p>
          At Dangayach Group, our team is committed to exceeding expectations
          and making a positive impact in the industry. We are a group of
          experts who are passionate about delivering exceptional results and
          driving innovation.
        </p>
      </div>
    </div>
  );
};

export default Team;
