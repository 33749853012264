import First from "../../../../Images/leMeridian1.jpeg";
import Second from "../../../../Images/leMeridian2.jpeg";
import Third from "../../../../Images/leMeridian3.jpeg";

const LeMeridianImages = [
  {
    title: `LeMeridian`,
    urls: First,
  },
  {
    title: `LeMeridian`,
    urls: Second,
  },
  {
    title: `LeMeridian`,
    urls: Third,
  },
];

export default LeMeridianImages;
