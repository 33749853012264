import React from "react";

import classes from './roomLeft.module.css';

function Dots({ activeIndex, onclick, sliderImage }) {
  return (
    <div className={classes['all-dot']}>
      {sliderImage.map((slide, index) => (
        <span
          key={index}
          className={`${classes.dots} ${activeIndex === index ? classes['active-dots'] : classes.dots}`}
          onClick={() => onclick(index)}
        ></span>
      ))}
    </div>
  );
}

export default Dots;
