import Founder from "./Founders/Founder";
import CoreValues from "./CoreValues/CoreValues";

import classes from "./OurStory.module.css";

const OurStory = () => {
  return (
    <div className={classes.container}>
      <div className={classes.slide}>
        <h1>Our Story</h1>
      </div>
      <div className={classes.info}>
        <h1>An Illustrious Legacy</h1>
        <h5>
          With over 50 years of excellence, the Dangayach Group has <br />
          become synonymous with leadership in diamond jewellery, global
          hospitality, <br /> and prestigious real estate, inspiring industries
          and captivating <br /> discerning connoisseurs worldwide.
        </h5>
      </div>
      <Founder />
      <CoreValues />
    </div>
  );
};

export default OurStory;
