import classes from "./Values.module.css";

const Values = (props) => {
  return (
    <div className={classes.containerValues}>
      <picture>
        <img src={props.image} alt="logo" />
      </picture>
      <h2>{props.title}</h2>
      <hr />
      <p>{props.description}</p>
    </div>
  );
};

export default Values;
