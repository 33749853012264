import React, { useEffect, useState } from "react";
import HotelSliderContent from "./HotelSliderContent";
import Dots from "./Dots";
import Arrows from "./Arrows";
import classes from "./roomRight.module.css";

function RoomSliderRight(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const file = props.RoomImages;
  const len = file.length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex, len]);

  return (
    <div className={classes.slidercontain}>
      <div className={classes.sliderPTag}>
        <a href={props.anch}>
          <h1>{props.category}</h1>
        </a>
        <p>{props.about}</p>
      </div>
      <div>
        <HotelSliderContent
          activeIndex={activeIndex}
          sliderImage={file}
          className={classes.sliderImage}
        />
        <Arrows
          prevSlide={() =>
            setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
          }
          nextSlide={() =>
            setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
          }
        />
        <Dots
          activeIndex={activeIndex}
          sliderImage={file}
          onclick={(activeIndex) => setActiveIndex(activeIndex)}
        />
      </div>
    </div>
  );
}

export default RoomSliderRight;
