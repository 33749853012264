import NewsBox from "./NewsBox/NewsBox";

import classes from "./NewsRoom.module.css";

const desc1 =
  "The exclusive signing ceremony, held at the Hotel Investment Conference South Asia (HICSA) in Bangalore, signals an exciting new phase of growth for Hilton as the company shapes the next golden age of travel.";

const desc2 =
  "Hilton and Dangayach Group on Wednesday announced the launch of Waldorf Astoria Jaipur, marking the debut of Waldorf Astoria Hotels & Resorts in India.";

const desc3 =
  "Hilton announced the signing of Hilton Sohna Resort & Spa, marking the debut of its flagship brand in the Delhi-NCR region. This marks Hilton’s second";

// const desc4 =
//   "Hilton and Dangayach Group have announced the signing of a branding and management agreement for the launch of Waldorf Astoria Jaipur, marking the debut of Waldorf Astoria Hotels ";

// const desc5 =
//   "Hilton has revealed its plans for the Hilton Sohna Resort & Spa, signifying the introduction of its main brand in the Delhi-NCR area.";

// const desc6 =
//   "Hilton's Waldorf Astoria brand has made its debut in India in Jaipur, as the hotel major looks at focusing on the luxury and wedding market in the country.";

const NewsRoom = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h1>News Room</h1>
      </div>
      <div className={classes.infoContainer}>
        <h1>Here are a few latest happenings at Dangayach Group:</h1>
        <p>
          Welcome to the Dangayach Group Newsroom! Here, you'll find all the
          latest updates, news, and happenings related to our organization. Stay
          informed about our exciting projects, industry advancements, awards,
          and more. Explore the dynamic world of Dangayach Group and be a part
          of our journey towards excellence.
        </p>
      </div>
      <div className={classes.newsBoxContainer}>
        <div className={classes.newsBox1}>
          <NewsBox
            pic={require("../../Images/info.png")}
            title="Hilton and Dangayach Group announce signing"
            date="April 13, 2023"
            description={desc1}
            anch="https://www.hotelierindia.com/operations/hilton-and-dangayach-group-announce-the-signing-of-a-branding-and-management-agreement-for-the-launch-of-waldorf-astoria-jaipur"
          />
          <NewsBox
            pic={require("../../Images/info2.jpg")}
            title="Hilton, Dangayach Group to bring Waldorf"
            date="April 13, 2023"
            description={desc2}
            anch="https://www.financialexpress.com/industry/hilton-dangayach-group-to-bring-waldorf-astoria-to-india/3044822/"
          />
          <NewsBox
            pic={require("../../Images/info3.jpg")}
            title="Hilton partners with Dangayach Group "
            date="June 21, 2023"
            description={desc3}
            anch="https://www.constructionweekonline.in/projects-tenders/after-waldorf-astoria-in-jaipur-hilton-partners-with-dangayach-group-again-to-launch-hilton-sohna-resort-spa"
          />
        </div>
        {/* <div className={classes.newsBox2}>
          <NewsBox
            pic={require("../../Images/info4.jpg")}
            title="Hilton & Dangayach Group Announces "
            date="April 13, 2023"
            description={desc4}
            anch="https://www.traveltrendstoday.in/hilton-dangayach-group-announces-debut-of-waldorf-astoria-in-india/"
          />
          <NewsBox
            pic={require("../../Images/info5.jpg")}
            title="Dangayach Group; to Open Hilton Sohna"
            date="June 22, 2023"
            description={desc5}
            anch="https://restaurant.indianretailer.com/news/hilton-to-open-first-branded-hotel-in-delhi-ncr.n21058"
          />
          <NewsBox
            pic={require("../../Images/info.png")}
            title="Hilton's Waldorf Astoria makes debut in India"
            date="June 7, 2023"
            description={desc6}
            anch="https://www.cnbctv18.com/travel/culture/hilton-waldorf-astoria-jaipur-debut-india-ceo-chris-nassetta-focus-on-luxury-wedding-market-16395921.htm"
          />
        </div> */}
      </div>
    </div>
  );
};

export default NewsRoom;
