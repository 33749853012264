import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={classes.container}>
      {/* <div className={classes.title}>
        <h1>CONTACT US</h1>
      </div>
      <div className={classes.boxContainer}>
        <div className={classes.box1}>
          <h3>EXPLORE SERVICES</h3>
          <h3>EXPLORE CAREERS</h3>
          <hr />
          <h6>LET’S TALK THE NEST — ASSOCIATE PORTAL</h6>
          <h6>TRANSPARENCY IN COVERAGE</h6>
          <p>© Dangayach Group 2023</p>
          <h3>Head Quarters</h3>
          <h6>
            Dangayach Group <br />B 6B Sterling Building, <br /> Prithviraj
            Road, C Scheme, <br />
            Jaipur- 302001
          </h6>
        </div>
        <div className={classes.box2}>
          <h3>CONTACT US</h3>
          <h4>
            Ph. 91-141-2377649 <br /> Fax 91-141-2377651 <br /> E-mail:
            Info@dangayachgroup.com 
          </h4>
          <hr />
          <h3>Jewelry Manufacturing:</h3>
          <h4>
            SEZ Zone, <br />
            Sitapura Industrial Area <br />
            Jaipur
          </h4>
        </div>
      </div> */}
      <p> ©Copyright Dangayach Group</p>
    </div>
  );
};

export default Footer;
