import First from "../../../../Images/marriottImage1.jpeg";
import Second from "../../../../Images/marriottImage2.jpeg";
import Third from "../../../../Images/marriottImage3.jpeg";

const MarriottImages = [
  {
    title: `Marriott`,
    urls: First,
  },
  {
    title: `Marriott`,
    urls: Second,
  },
  {
    title: `Marriott`,
    urls: Third,
  },
];

export default MarriottImages;
