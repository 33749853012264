import classes from "./Founder.module.css";

const Founder = () => {
  return (
    <div className={classes.container}>
      <div className={classes.boxLeft}>
        <div className={classes.photoLeft}>
          <picture>
            <source
              media="(max-width: 700px)"
              srcSet={require("../../../Images/Dangayach_390x270_Phone.png")}
            />
            <source
              media="(max-width: 1100px) and (min-width: 700px)"
              srcSet={require("../../../Images/Dangayach_410x490_Ipad.png")}
            />
            <source
              media="(min-width: 1100px)"
              srcSet={require("../../../Images/Founder1.png")}
            />
            <img src={require("../../../Images/Founder1.png")} alt="Founder" />
          </picture>
        </div>
        <div className={classes.contentLeft}>
          <h1>Mr. Hari Mohan Dangayach</h1>
          <h3>Chairman</h3>
          <p>
            Renowned visionary Mr. Hari Mohan Dangayach drove the remarkable
            journey of the Dangayach Group. With 40+ years of experience, he set
            high standards, expanded into diverse industries, and excelled in
            Hotels and Gems and Jewellery exports. His successful global
            jewellery business spread his values. His strategic prowess and
            unwavering commitment made the group an innovative beacon, setting
            new success benchmarks.
          </p>
        </div>
      </div>
      <div className={classes.boxRight}>
        <div className={classes.contentRight}>
          <h1>Mr. Atul Dangayach</h1>
          <h3>M.D.</h3>
          <p>
            Mr. Atul Dangayach, the gifted son of Shri Hari Mohan Dangayach,
            carries forward the celebrated legacy of the Dangayach Group.
            Educated in the USA, he brings a fresh perspective and global
            mindset. With unwavering commitment, he expands horizons,
            diversifies the portfolio, and embraces innovation. Through strong
            alliances and strategic thinking, Mr. Atul Dangayach navigates
            dynamic industries, setting new benchmarks. With a keen eye for
            trends, he ensures the group remains at the forefront of innovation
            and growth.
          </p>
        </div>
        <div className={classes.photoRight}>
          <picture>
            <source
              media="(max-width: 700px)"
              srcSet={require("../../../Images/Dangayach02_390x270_Phone.png")}
            />
            <source
              media="(max-width: 1100px) and (min-width: 700px)"
              srcSet={require("../../../Images/Dangayach02_410x490_Ipad.png")}
            />
            <source
              media="(min-width: 1100px)"
              srcSet={require("../../../Images/Founder2.png")}
            />
            <img src={require("../../../Images/Founder2.png")} alt="Founder" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default Founder;
