import First from "../../../../Images/JECC - Porch Area - Convention Centre View.jpg";
import Second from "../../../../Images/JECC - Auditorium Seating 740 chairs Ball Room - 1100 SQM.jpg";
import Third from "../../../../Images/JECC - VIP Lounge attached with Ballroom.jpg";

const NovotelJaipurImages = [
  {
    title: `Novotel Jaipur`,
    urls: First,
  },
  {
    title: `Novotel Jaipur`,
    urls: Second,
  },
  {
    title: `Novotel Jaipur`,
    urls: Third,
  },
];

export default NovotelJaipurImages;
