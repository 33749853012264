import Slider from './Slider/Slider';
import SliderSmall from "./SmallScreenSlider/SliderSmall";
import Info from './Info/Info';
// import Hospitality from "./Hospitality/Hospitality";
import Message from './Message/Message';
import LogoSlider from "./LogoSlider/LogoSlider";
import { useMediaQuery } from "react-responsive";

// import { useMediaQuery } from "react-responsive";

import { motion } from "framer-motion";

const Home = () => {
  const isDesktopOrSmaller = useMediaQuery({
    query: "(min-width: 700px)",
  });

  return (
    <motion.div
      intial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      {isDesktopOrSmaller ? <Slider /> : <SliderSmall />}
      <Info />
      {/* <Hospitality /> */}
      <Message />
      <LogoSlider />
    </motion.div>
  );
};

export default Home;
