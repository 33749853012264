import First from "../../../../Images/RadissionAgra1.jpeg";
import Second from "../../../../Images/RadissionAgra2.jpeg";
import Third from "../../../../Images/RadissionAgra3.jpeg";

const RadissionImages = [
  {
    title: `Radission`,
    urls: First,
  },
  {
    title: `Radission`,
    urls: Second,
  },
  {
    title: `Radission`,
    urls: Third,
  },
];

export default RadissionImages;
