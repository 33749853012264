import JewelBox from "./jewelBox/JewelBox";
import classes from "./Jewelry.module.css";

const Jewelry = () => {
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <h1>Where Elegance Meets Craftsmanship</h1>
      </div>
      <div className={classes.infoContainer}>
        <h1>Crafting Brilliance for 25+ Years</h1>
        <p>
          The Dangayach Group excels in jewelry, known for its intricate
          designs, setting industry standards. Every creation stands as an
          individual work of art, never to be replicated, exclusively crafted
          for the export market, with the US being its primary focal point.
          These pieces represent red carpet elegance, embodying <br /> unmatched
          magnificence.
        </p>
      </div>
      <div className={classes.jewelBoxContainer}>
        <div className={classes.jewelBox1}>
          <JewelBox
            pic={require("../../Images/image9.png")}
            pic2={require("../../Images/Image02_384x300_Phone.png")}
            title="Contemporary Flair "
          />
          <JewelBox
            pic={require("../../Images/image8.png")}
            pic2={require("../../Images/Image03_384x300_Phone.png")}
            title="Timeless Grace"
          />
        </div>
        <div className={classes.jewelBox2}>
          <JewelBox
            pic={require("../../Images/image6.jpeg")}
            pic2={require("../../Images/Image04_384x300_Phone.png")}
            title="Delightful Danglers "
          />
          <JewelBox
            pic={require("../../Images/image10.jpeg")}
            pic2={require("../../Images/Image01_384x300_Phone.png")}
            title="Briolette Earrings"
          />
        </div>
        <div className={classes.jewelBox3}>
          <JewelBox
            pic={require("../../Images/image12.jpeg")}
            pic2={require("../../Images/Image05_384x300_Phone.png")}
            title="Rose Cut Earrings"
          />
          <JewelBox
            pic={require("../../Images/image11.jpeg")}
            pic2={require("../../Images/Image06_384x300_Phone.png")}
            title="Pearl Drop Earrings"
          />
        </div>
      </div>
    </div>
  );
};

export default Jewelry;
