import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import { NavLink } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  const navRef = useRef();

  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div className="container">
      <div className="logoContainer">
        <a href="http://nextad.co.in/">
          <picture className="logoImage">
            <img src={require("../../Images/logo_24.png")} alt="NextAd" />
          </picture>
        </a>
      </div>
      <header className="linkContainer">
        <nav className="linker" ref={navRef}>
          <div className="leftNav">
            <NavLink
              className="navLink"
              to="/"
              // activeClassName="active"
              onClick={showNavBar}
            >
              Home
            </NavLink>
            <NavLink
              className="navLink"
              to="/OurStory"
              // activeClassName="active"
              onClick={showNavBar}
            >
              Our Story
            </NavLink>
            <NavLink
              className="navLink"
              to="/Portfolio"
              // activeClassName="active"
              onClick={showNavBar}
            >
              Portfolio
            </NavLink>
          </div>
          <div className="rightNav">
            <NavLink
              className="navLink"
              to="/NewsRoom"
              // activeClassName="active"
              onClick={showNavBar}
            >
              News Room
            </NavLink>
            <NavLink
              className="navLink"
              to="/Team"
              // activeClassName="active"
              onClick={showNavBar}
            >
              Team
            </NavLink>
            <NavLink
              className="navLink"
              to="/ContactUs"
              // activeClassName="active"
              onClick={showNavBar}
            >
              Contact Us
            </NavLink>
          </div>
          <button className="nav-btn nav-close-btn" onClick={showNavBar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavBar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};

export default NavBar;
