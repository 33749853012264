import React from "react";

import classes from "./roomRight.module.css";

function HotelSliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className= {`${classes.slides} ${index === activeIndex ?  classes.active : classes.inactive}`}
        >
          <img className={classes.imageroom} src={slide.urls} alt="" />
        </div>
      ))}
    </section>
  );
}

export default HotelSliderContent;
