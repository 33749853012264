import First from "../../Images/image1.jpg";
import Second from "../../Images/image2.jpg";
import Third from "../../Images/image3.jpg";

const imageSlider = [
  {
    // title: `JAIPUR`,
    // description: "",
    urls: First,
  },
  {
    // title: `JAIPUR`,
    // description: "",
    urls: Second,
  },
  {
    // title: `JAIPUR`,
    // description: "",
    urls: Third,
  },
];

export default imageSlider;
