import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import Home from "../Components/Home";
import OurStory from "../Pages/OurStory/OurStory";
import Portfolio from "../Pages/Portfolio/Portfolio";
import NewsRoom from "../Pages/NewsRoom/NewsRoom";
import Team from "../Pages/Team/Team";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Jewelry from "../Pages/Jewelry/Jewelry";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = (props) => {
  const location = useLocation();
  let scroller = props.scroller;
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} scroller={scroller} />
        <Route path="/OurStory" element={<OurStory />} scroller={scroller} />
        <Route path="/Portfolio" element={<Portfolio />} scroller={scroller} />
        <Route path="/NewsRoom" element={<NewsRoom />} scroller={scroller} />
        <Route path="/Team" element={<Team />} scroller={scroller} />
        <Route path="/ContactUs" element={<ContactUs />} scroller={scroller} />
        <Route path="/jewelry" element={<Jewelry />} />
        <Route path="*" element={<Navigate to="/" />} scroller={scroller} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
