import React, { Fragment, useState } from "react";

import classes from "./JewelBox.module.css";

const JewelBox = (props) => {
  const [file, setFile] = useState();

  return (
    <Fragment>
      <div className={classes.container}>
        <div
          onClick={() => {
            setFile(props.pic);
          }}
        >
          <picture>
            <source
              media="(max-width: 1100px)"
              srcSet={props.pic2}
            />
            <source
              media="(min-width: 1100px)"
              srcSet={props.pic}
            />
            <img
              src={props.pic}
              alt="Jewelry"
              className={classes.containerPic}
            />
          </picture>
          <h1>{props.title}</h1>
        </div>
        <div
          className={classes.popup}
          style={{ display: file ? "block" : "none" }}
        >
          <span onClick={() => setFile(null)}>&times;</span>
          <img src={file} alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default JewelBox;
