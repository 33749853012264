import classes from "./Portfolio.module.css";
import Hotel from "./Slider/Hotel";

const Portfolio = () => {
  return (
    <div className={classes.container}>
      <div className={classes.slide}>
        <h1>Portfolio</h1>
      </div>
      <div className={classes.info}>
        <p>
          Step into our portfolio and witness a showcase of excellence. Explore
          <br />
          a captivating collection of our finest work, where every project tells
          a unique story of <br /> innovation, craftsmanship, and success. Our
          portfolio is a testament to our unwavering <br /> commitment to
          delivering exceptional results.
        </p>
      </div>
      <div className={classes.ourHotels}>
        <div className={classes.sliderInfo}>
          <h1>Our Hotels</h1>
        </div>
        <div className={classes.slideContainer}>
          <Hotel />
        </div>
      </div>
      <div className={classes.upcomingHotels}>
        <h1>Upcoming Hotels...</h1>
        <ul>
          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_Marriott.png")}
              alt="Marriott"
            />
            <p>Marriott Udaipur</p>
          </div>

          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_WaldorfAstoria.png")}
              alt="Waldorf Astoria"
            />
            <p>Waldorf Astoria Jaipur</p>
          </div>

          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_DangaychLogo.png")}
              alt="Jaisalmer"
            />
            <p>Jaisalmer Resort</p>
          </div>

          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_HiltonResort.png")}
              alt="Hilton"
            />
            <p>Hilton Sohna Resort</p>
          </div>

          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_HiltonGarden.png")}
              alt="Hilton"
            />
            <p>Hilton Garden Inn Sahibabad</p>
          </div>

          <div className={classes.upcomingNameContainer}>
            <img
              src={require("../../Images/Portfolio_Logo_DangaychLogo.png")}
              alt="Bangalore"
            />
            <p>Bangalore Airport Hotel</p>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Portfolio;
