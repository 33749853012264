import classes from "./Contact.module.css";

const ContactUs = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h1>Contact Us</h1>
      </div>
      <div className={classes.infoContainer}>
        <h1>Let's Connect</h1>
        <p>
          We're just a click or call away! Reach out to us for any inquiries,
          feedback, or collaboration opportunities. We welcome your questions,
          comments, ideas, and opportunities.
        </p>
      </div>
      <div className={classes.headquarterContainer}>
        <div className={classes.headquarterContainerText}>
          <h1>OUR HEADQUARTERS</h1>
          <p>
            Dangayach Group
            <br />
            1st Floor Jaipur Marriott
            <br />
            Near Jawahar Circle,
            <br /> Ashram Marg,,
            <br /> Jaipur- 302015
            <br />
            <br />
            Ph. 91-141-2377649 <br />
            Fax 91-141-2377651
            <br />
            <br />
            E-mail: Info@dangayachgroup.com
          </p>
        </div>
        <div className={classes.headquarterContainerImage}>
          <picture>
            <source
              media="(max-width: 700px)"
              srcSet={require("../../Images/ContactUs_330x500_Phone.png")}
            />
            <source
              media="(max-width: 1100px) and (min-width: 700px)"
              srcSet={require("../../Images/ContactUs_700x470_Ipad.png")}
            />
            <source
              media="(min-width: 1100px)"
              srcSet={require("../../Images/contactUsHead.jpg")}
            />
            <img
              src={require("../../Images/contactUsHead.jpg")}
              alt="HeadQuarters"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
