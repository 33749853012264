import { useRef } from "react";

import AnimatedRoutes from "./AnimatedRoutes/AnimatedRoutes";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";

function App() {
  const myRef = useRef();
  const handleClick = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <NavBar />
      <main>
        <AnimatedRoutes scroller={handleClick} />
      </main>
      <Footer />
    </div>
  );
}

export default App;
