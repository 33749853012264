import First from "../../../../Images/Dangayach_Chomu_IMG01.png";
import Second from "../../../../Images/Dangayach_Chomu_IMG02.png";
import Third from "../../../../Images/Dangayach_Chomu_IMG03.png";

const ChomuPalaceImages = [
  {
    title: `Chomu Palace`,
    urls: First,
  },
  {
    title: `Chomu Palace`,
    urls: Second,
  },
  {
    title: `Chomu Palace`,
    urls: Third,
  },
];

export default ChomuPalaceImages;
