import classes from "./Slider.module.css";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={`${classes.slides} ${
            index === activeIndex ? classes.active : classes.inactive
          }`}
        >
          <img className={classes["slide-image"]} src={slide.urls} alt="" />
          <h2 className={classes["slide-title"]}>
            {slide.title}
          </h2>
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
