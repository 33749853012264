import First from "../../Images/Amer_01.png";
import Second from "../../Images/JalMahal_01.png";
import Third from "../../Images/Nahargarh_01.png";

const imageSlider = [
  {
    urls: First,
  },
  {
    urls: Second,
  },
  {
    urls: Third,
  },
];

export default imageSlider;
