import classes from "./Message.module.css";

const Message = () => {
  return (
    <div className={classes.container}>
      <diV className={classes.title}>
        <h1>Message from the Team</h1>
      </diV>
      <div className={classes.boxContainer}>
        <div className={classes.box}>
          <div className={classes.video}>
            <iframe
              width="398"
              height="320"
              src="https://www.youtube.com/embed/1oanR_9AK5E"
              title="hello"
            />
          </div>
          <div className={classes.text}>
            <h1>
              Unfolding the Secrets of our Excellence | Mr. Harimohan Dangayach,
              Chairman
            </h1>
            <p>August 09 2023</p>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.video}>
            <iframe
              width="398"
              height="320"
              src="https://www.youtube.com/embed/uY9smqO0Eqk?rel=0&autoplay=1"
              title="hello"
              allowfullscreen
            />
          </div>
          <div className={classes.text}>
            <h1>
              A Message from our MD Mr. Atul Dangayach | Dangayach Group |
              Jaipur
            </h1>
            <p>August 09 2023</p>
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.video}>
            <iframe
              width="398"
              height="320"
              src="https://www.youtube.com/embed/_wfkZ9O-qYs"
              title="hello"
              allowfullscreen
            />
          </div>
          <div className={classes.text}>
            <h1>Know all About Our Jewelry Venture | Mr. Atul Dangayach MD</h1>
            <p>August 12 2023</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
