import { useNavigate } from "react-router-dom";
import classes from "./Info.module.css";


const Info = () => {
  const navigate = useNavigate();

  const navigateToJewelryPage = (e) => {
    e.preventDefault();
    navigate("/jewelry");
  };

  const navigateToPortfolioPage = (e) => {
    e.preventDefault();
    navigate("/Portfolio");
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <h1>WHAT WE DO</h1>
        <p>
          Driving growth and innovation across Hospitality, Real Estate, <br />{" "}
          and Education with excellence.
        </p>
      </div>
      <div className={classes.ourStory}>
        <div className={classes.storyImage}>
          <picture>
            <source
              media="(max-width: 700px)"
              srcSet={require("../../Images/Dangayach_phone.png")}
            />
            <source
              media="(max-width: 1100px) and (min-width: 700px)"
              srcSet={require("../../Images/DSC00106.jpg")}
            />
            <source
              media="(min-width: 1100px)"
              srcSet={require("../../Images/DSC00106.jpg")}
            />
            <img src={require("../../Images/DSC00106.jpg")} alt="Speaker" />
          </picture>
        </div>
        <div className={classes.storyText}>
          <h1>OUR STORY</h1>
          <h3>
            Empowering industries <br /> through relentless <br /> innovation
          </h3>
          <p>
            Founded by the visionary Chairman Mr. Hari Mohan Dangayach, the
            Dangayach Group's extraordinary odyssey began over 50 years ago.
            From a humble gemstone business to becoming the leader in Diamond
            jewellery, global hospitality, and prestigious real estate. The
            relentless pursuit of excellence continues to ignite innovation in
            these esteemed domains.
          </p>
        </div>
      </div>
      <div className={classes.types}>
        <div className={classes.typeBox1}>
          <div className={classes.typeBoxImage1}>
            <picture>
              <source
                media="(max-width: 700px)"
                srcSet={require("../../Images/MarriottIMG_Phone.png")}
              />
              <source
                media="(max-width: 1100px) and (min-width: 700px)"
                srcSet={require("../../Images/MarriottIMG_Ipad.png")}
              />
              <source
                media="(min-width: 1100px)"
                srcSet={require("../../Images/marriottImage3.jpeg")}
              />
              <img
                src={require("../../Images/marriottImage3.jpeg")}
                alt="Speaker"
              />
            </picture>
          </div>
          <div className={classes.typeBoxText1}>
            <h1>Hospitality</h1>
            <p>
              The Dangayach Group has emerged as a prominent force in the hotel
              industry, boasting a diverse portfolio of owned and operated
              hotels. Renowned for their luxurious interiors, cutting-edge
              amenities, impeccable personalized services and strong brand
              affiliation to cater to the discerning needs of travelers.
            </p>
            <button onClick={navigateToPortfolioPage}>Know More</button>
          </div>
        </div>
        <div className={classes.typeBox2}>
          <div className={classes.typeBoxText2}>
            <h1>Jewelry</h1>
            <p>
              The Dangayach Group is a prominent player in the jewelry sector,
              renowned for their exquisite designs crafted for export market.
              With a global customer base, we have earned a reputation for
              exceptional quality, craftsmanship, and excellence.
            </p>
            <button onClick={navigateToJewelryPage}>Know More</button>
          </div>
          <div className={classes.typeBoxImage2}>
            <picture>
              <source
                media="(max-width: 700px)"
                srcSet={require("../../Images/JewelleyIMG_Phone.png")}
              />
              <source
                media="(max-width: 1100px) and (min-width: 700px)"
                srcSet={require("../../Images/JewelleyIMG_Ipad.png")}
              />
              <source
                media="(min-width: 1100px)"
                srcSet={require("../../Images/Dangayach_Jewellery_IMG.png")}
              />
              <img
                src={require("../../Images/Dangayach_Jewellery_IMG.png")}
                alt="Speaker"
              />
            </picture>
          </div>
        </div>
        <div className={`${classes.typeBox1} ${classes.DSHM}`}>
          <div className={classes.typeBoxImage1}>
            <picture>
              <source
                media="(max-width: 700px)"
                srcSet={require("../../Images/DSHM_Phone.png")}
              />
              <source
                media="(max-width: 1100px) and (min-width: 700px)"
                srcSet={require("../../Images/DSHM_Ipad.png")}
              />
              <source
                media="(min-width: 1100px)"
                srcSet={require("../../Images/DSHM.JPG")}
              />
              <img src={require("../../Images/DSHM.JPG")} alt="Speaker" />
            </picture>
          </div>
          <div className={`${classes.typeBoxText1} ${classes.DSHM}`}>
            <h1>DSHM Jaipur</h1>
            <h2>Framing the Future of Hospitality</h2>
            <h3>Where Education Meets Empowerment </h3>
            <p>
              The Dangayach School of Hotel Management, an embodiment of the
              Dangayach Group's unwavering commitment to excellence, nurtures
              aspiring leaders in the realm of hospitality. Through a holistic
              curriculum, state-of-the-art facilities, and esteemed faculty, the
              school equips students with knowledge, skills, and values that
              redefine service standards. Graduates carry a prestigious legacy,
              poised to make a lasting impact on the global hospitality
              landscape.
            </p>
            <a href="https://www.dshmjaipur.com/">
              <button>Know More</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
