import classes from "./LogoSlider.module.css";

const LogoSlider = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h1>Trusted Partners</h1>
      </div>
      <div className={classes.slider}>
        <div className={classes["slide-track"]}>
          <div className={classes.slide}>
            <img
              src={require("../../Images/Wyndham_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img
              src={require("../../Images/Radisson_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img
              src={require("../../Images/Marriott_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img src={require("../../Images/Hilton_Logo_01.png")} alt="slide" />
          </div>
          <div className={classes.slide}>
            <img src={require("../../Images/Accor_Logo_01.png")} alt="slide" />
          </div>
          ˝{/* same 9 SLides */}
          <div className={classes.slide}>
            <img
              src={require("../../Images/Wyndham_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img
              src={require("../../Images/Radisson_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img
              src={require("../../Images/Marriott_Logo_01.png")}
              alt="slide"
            />
          </div>
          <div className={classes.slide}>
            <img src={require("../../Images/Hilton_Logo_01.png")} alt="slide" />
          </div>
          <div className={classes.slide}>
            <img src={require("../../Images/Accor_Logo_01.png")} alt="slide" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
